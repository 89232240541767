/* Material Icons Font (for MD theme) */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"),
    url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* Framework7 Icons Font (for iOS theme) */
@font-face {
  font-family: "Framework7 Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Framework7Icons-Regular.eot");
  src: url("../fonts/Framework7Icons-Regular.woff2") format("woff2"),
    url("../fonts/Framework7Icons-Regular.woff") format("woff"),
    url("../fonts/Framework7Icons-Regular.ttf") format("truetype");
}
.f7-icons {
  font-family: "Framework7 Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 28px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  font-feature-settings: "liga";
  text-align: center;
}
