.toolbar.item-toolbar{
  --f7-toolbar-height: 60px;
  --f7-bars-translucent-opacity: 0.7;
  --f7-bars-translucent-blur: 3px;
  --f7-toolbar-inner-padding-right: 0px;
  --f7-toolbar-inner-padding-left: 0px;
}
.tabbar.item-tabbar{
  --f7-toolbar-bg-color-rgb: white; 
   .toolbar-inner{
     padding: 0;
     font-size: 0.6em;
   }
}