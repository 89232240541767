@import 'line-awesome.min.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('~dropzone/dist/dropzone.css');
@import 'swiper/components/pagination/pagination.less';
@import './items.less';

/* iOS Cordova Tweak */
.device-ios {
  height: 100vh;
}

:root {
  --f7-theme-color: #2ea7e0;
  --f7-theme-color-rgb: 79, 70, 229;
  --f7-theme-color-shade: #007baf;
  --f7-theme-color-tint: #6edcff;
  --f7-page-bg-color: white;
  --f7-tabbar-link-active-color: #000000;
  --f7-tabbar-icon-size: 24px;
  --f7-input-font-size: 14px;
  --f7-input-padding-left: 10px;
  --f7-input-padding-right: 10px;
  --f7-navbar-title-font-size: 16px;
  --f7-navbar-height: 50px;
}

input[type='text'].border-gray-300,
select.border-gray-300,
textarea.border-gray-300 {
  border-radius: 0.375rem;
  border: 1px solid var(--border-color);
  --border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.display-linebreak {
  white-space: pre-line;
}

.list {
  border: none;
}

.device-android {
  --f7-page-transition-duration: 250ms;
}

.device-android .page-next {
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 56px, 0);
}

.device-android .page-next.page-next-on-right {
  transform: translate3d(100%, 0, 0);
}

.device-android .router-transition-forward .page-next {
  animation: md-page-next-to-current var(--f7-page-transition-duration) forwards;
}

.device-android .router-transition-forward .page-current {
  animation: none;
}

.device-android .router-transition-backward .page-current {
  animation: md-page-current-to-next var(--f7-page-transition-duration) forwards;
}

.device-android .router-transition-backward .page-previous {
  animation: none;
}

.ios.device-android .page-previous {
  transform: translate3d(0, 0, 0);
}

.ios.device-android .router-transition-forward .page-next:before {
  animation: none;
}

.grid-list-item {
  .item-link {
    width: 100%;

    .item-content {
      padding: 0;
      display: inline;
    }
  }

  .item-media {
    padding-bottom: 0;

    & + .item-inner {
      width: 10rem;
      margin: auto;
      padding-right: 0 !important;

      .item-title-row {
        padding: 0;
      }

      .item-title-row::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }
  }
}

// .ios .trans-navbar .navbar-bg:after {
//   display: none;
// }

.bg-trans {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01));
}

.text-xxs {
  font-size: 8px;
}
